<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12 lg12>
					<pui-field-set :title="getTitleFieldSet1">
						<v-row dense>
							<v-flex xs12 md12 lg4 x4>
								<pui-select
									id="documentextrafields-doctype"
									attach="documentextrafields-doctype"
									:label="this.$t('documentextrafields.typedescription')"
									toplabel
									v-model="model"
									modelName="vlupotherdoctypes"
									:disabled="!isCreatingElement"
									reactive
									required
									clearable
									:modelFormMapping="{ doctypeid: 'doctypeid' }"
									:itemsToSelect="itemsToSelectDoctype"
									:itemValue="['doctypeid']"
									:itemText="(item) => `${item.typedescription}`"
									:fixedFilter="filterDoctype"
									:order="{ typedescription: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12 md12 lg4 x4>
								<pui-text-field
									:label="$t('documentextrafields.description')"
									v-model="model.puidocument.description"
									maxlength="100"
									toplabel
									noeditable
									:disabled="formDisabled"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md12 lg4 x4 v-show="this.$router.currentRoute.path.startsWith('/portcalls')">
								<pui-select
									:id="`documentextrafields-operationid`"
									attach="documentextrafields-operationid"
									:label="this.$t('documentextrafields.operation')"
									v-model="model"
									reactive
									toplabel
									:required="this.$router.currentRoute.path.startsWith('/portcalls')"
									modelName="vlupmoperation"
									:itemsToSelect="itemsToSelectOperation"
									:itemValue="['operationid']"
									:modelFormMapping="{ operationid: 'operationid' }"
									:itemText="(item) => `${item.operationdesc}`"
									:fixedFilter="filteriscargo"
								></pui-select>
							</v-flex>
						</v-row>
						<v-row dense>
							<v-flex xs12 md12 lg6 x6>
								<pui-text-field
									:label="$t('form.puidocument.filename')"
									v-model="model.puidocument.filenameorig"
									toplabel
									required
									disabled
								></pui-text-field>
								<input type="file" v-show="false" ref="document" :accept="fileExtensions" @change="onFilePicked" />
							</v-flex>
							<v-flex xs12 md12 lg6 x6>
								<v-btn
									id="selectFile"
									class="header_info_btn primary"
									v-if="isCreateMethod"
									@click="pickFile"
									style="margin-top: 20px"
								>
									{{ $t('form.puidocument.selectfile') }}
								</v-btn>
								<v-btn class="header_info_btn primary ml-1 mt-6" v-if="!isCreateMethod" @click="downloadFile">
									{{ $t('form.puidocument.downloadfile') }}
								</v-btn>
								<v-btn
									class="header_info_btn primary ml-1 mt-6"
									v-if="!isCreateMethod && !isXlsOrXlsxFile(model.puidocument.filenameorig)"
									@click="openFile"
									style="margin-top: 20px; min-width: 120px"
								>
									{{ $t('form.puidocument.openfile') }}
								</v-btn>
							</v-flex>
						</v-row>
						<v-row dense v-if="!isMobile && isCreateMethod">
							<v-flex xs12 md12 lg6 x6>
								<div class="drop align-center" @dragover.prevent @dragenter="onDragEnter" @dragleave="onDragLeave" @drop="onDrop">
									<div class="helper"></div>
									<label v-show="!model.puidocument.filenameorig" class="label-file">{{
										$t('form.puidocument.dropfilehere')
									}}</label>
									<i v-show="model.puidocument.filenameorig" :class="selectedFileType" style="font-size: 30px"></i>
									<label v-show="model.puidocument.filenameorig" class="label-file">{{ model.puidocument.filenameorig }}</label>
								</div>
							</v-flex>
						</v-row>
						<br />
						<br />
						<v-row dense>
							<v-flex xs12 md12 lg4 x4 v-if="this.showCertificateFields && this.model.doctypeid == 7">
								<pui-text-field
									:label="$t('documentextrafields.docnumber')"
									v-model="model.docnumber"
									toplabel
									:disabled="formDisabled"
								></pui-text-field>
								<!--
								<pui-select
									id="`documentextrafields-port`"
									attach="documentextrafields-port"
									:label="$t('documentextrafields.port')"
									toplabel
									required
									v-model="model"
									modelName="mports"
									:disabled="formDisabled"
									reactive
									:modelFormMapping="{ countrycode: 'issuecountry', portcode: 'issueport' }"
									:itemsToSelect="itemsToSelectPort"
									:itemValue="['countrycode', 'portcode']"
									:itemText="(item) => `${item.locode} - ${item.portdescription}`"
									:order="{ locode: 'asc' }"
								></pui-select>
								-->
							</v-flex>
							<v-flex xs12 md12 lg4 x4 v-if="this.showCertificateFields">
								<pui-text-field
									:label="$t('documentextrafields.issuer')"
									v-model="model.issuer"
									maxlength="256"
									toplabel
									required
									:disabled="formDisabled"
								></pui-text-field>
							</v-flex>
						</v-row>
						<v-row dense>
							<v-flex xs12 md12 lg4 x4 v-if="this.showCertificateFields">
								<pui-date-field
									:label="$t('documentextrafields.issued')"
									v-model="model.issued"
									:disabled="formDisabled"
									toplabel
									required
									:max="new Date()"
								></pui-date-field>
							</v-flex>

							<v-flex
								xs12
								md12
								lg4
								x4
								v-if="this.showCertificateFields && this.showExpireDate && this.model.doctypeid != 6 && this.model.doctypeid != 15"
							>
								<pui-date-field
									:label="$t('documentextrafields.expires')"
									v-model="model.expires"
									:disabled="formDisabled"
									required
									toplabel
									:min="new Date()"
								></pui-date-field>
							</v-flex>
						</v-row>
						<v-row dense>
							<v-flex xs12 md12 lg4 x4 v-if="this.showCertificateFields">
								<pui-date-field
									:label="$t('documentextrafields.lastsurvey')"
									v-model="model.lastsurvey"
									:disabled="formDisabled"
									toplabel
									:max="new Date()"
								></pui-date-field>
							</v-flex>
						</v-row>
					</pui-field-set>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
		<!-- Empty file selected, show dialog -->
		<pui-modal-dialog
			v-if="showEmptyFileDialog"
			:okText="$t('pui9.accept')"
			:titleText="$t('form.puidocument.error.emptyfileTitle')"
			:messageText="$t('form.puidocument.error.emptyfile')"
			:dialogName="`emptyFileDialog-${this.modelName}`"
		></pui-modal-dialog>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import DocumentExtraFieldsActions from './DocumentExtraFieldsActions';
import {
	IS_SHIP_RELATED_COLUMN,
	ENDPOINT_DOCUMENT_UPLOAD,
	IS_CARGO_COLUMN,
	DOC_TYPE_ID_COLUMN
} from '../../constants/documents/DocumentExtraFieldsConstants';

export default {
	name: 'documentextrafieldsportcall-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'documentextrafieldsportcall',
			documentInfo: this.$puiUtils.getPathModelMethodPk(this.$router, 'documentextrafieldsportcall'),
			isCreateMethod: true,
			headers: [],
			showLanguages: false,
			showRoles: false,
			roleItems: [],
			fileExtensions: null,
			fileTypes: {
				'image/png': 'fas fa-file-image',
				'image/jpeg': 'fas fa-file-image',
				'application/pdf': 'fas fa-file-pdf',
				'application/vnd.ms-excel': 'fas fa-file-excel',
				'application/vnd.oasis.opendocument.spreadsheet': 'fas fa-file-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fas fa-file-excel',
				'application/msword': 'fas fa-file-word',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fas fa-file-word',
				'application/vnd.oasis.opendocument.text': 'fas fa-file-word',
				'application/vnd.ms-powerpoint': 'fas fa-file-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fas fa-file-powerpoint',
				'application/vnd.oasis.opendocument.presentation': 'fas fa-file-powerpoint',
				'text/xml': 'fas fa-file-alt',
				'text/plain': 'fas fa-file-alt'
			},
			selectedFile: null,
			selectedFileType: null,
			showEmptyFileDialog: false,
			showCertificateFields: false,
			showExpireDate: true,
			type: ''
		};
	},
	computed: {
		itemsToSelectOperation() {
			return [{ operationid: this.model.operationid }];
		},
		itemsToSelectPort() {
			return [{ countrycode: this.model.issuecountry, portcode: this.model.issueport }];
		},
		getTitleFieldSet1() {
			return this.$t('documentextrafields.fieldset.main');
		},
		itemsToSelectDoctype() {
			return [{ doctypeid: this.model.doctypeid }];
		},
		filteriscargo() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'operationid', op: 'in', data: [3, 4] }]
					}
				]
			};
		},
		filterDoctype() {
			if (this.documentInfo.parent.modelName === 'mships') {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: IS_SHIP_RELATED_COLUMN, op: 'eq', data: 1 }]
						}
					]
				};
			} else if (this.documentInfo.parent.modelName === 'portcalls') {
				let group1 = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: IS_CARGO_COLUMN, op: 'eq', data: '0' },
						{ field: IS_SHIP_RELATED_COLUMN, op: 'eq', data: '0' }
					]
				};

				let groups = [group1];

				groups.push({
					groupOp: 'and',
					groups: [],
					rules: [{ field: DOC_TYPE_ID_COLUMN, op: 'eq', data: 27 }]
				});

				const filter = { groupOp: 'or', rules: [], groups: groups };

				return filter;
			} else {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: IS_SHIP_RELATED_COLUMN, op: 'eq', data: 0 }]
						}
					]
				};
			}
		}
	},
	created() {
		if (!this.documentInfo || !this.documentInfo.parent.modelName) {
			return;
		}

		let parent = this.documentInfo.parent;
		while (parent != null) {
			this.headers.unshift(parent.modelName + 'formheader');
			parent = parent.parent;
		}

		if (this.documentInfo.method !== 'create') {
			this.isCreateMethod = false;
		}

		let modelName = this.documentInfo.parent.modelName;
		if (this.$store.state.puidocumentModels && this.$store.state.puidocumentModels[modelName]) {
			modelName = this.$store.state.puidocumentModels[modelName];
		}
		const params = {
			model: modelName
		};
		this.$puiRequests.getRequest(
			'/puidocument/getExtensionsForModel',
			params,
			(response) => {
				if (response && response.data) {
					for (var key in response.data) {
						if (!this.fileExtensions) {
							this.fileExtensions = '.' + response.data[key];
						} else {
							this.fileExtensions += ',.' + response.data[key];
						}
					}
				}
			},
			() => {}
		);

		this.compressImage =
			this.$store.state.puidocument &&
			this.$store.state.puidocument[this.documentInfo.parent.modelName] &&
			this.$store.state.puidocument[this.documentInfo.parent.modelName].compressImage;
	},
	mounted() {
		this.$puiEvents.$on(`pui-modalDialog-emptyFileDialog-${this.modelName}-ok`, () => {
			this.showEmptyFileDialog = false;
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-documentextrafields-doctype', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				if (lupaModel.iscertificate === 1) {
					this.showCertificateFields = true;
				} else {
					this.setFieldsToNull();
					this.showCertificateFields = false;
				}

				if (lupaModel.doctypeid === 7) {
					this.showExpireDate = false;
					this.model.expires = null;
				} else {
					this.showExpireDate = true;
				}
			} else {
				this.setFieldsToNull();
				this.showCertificateFields = false;
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-emptyFileDialog-${this.modelName}-ok`);
		this.$puiEvents.$off(`onPuiSelect_selectedItems-documentextrafields-doctype`);
	},
	methods: {
		isXlsOrXlsxFile(filename) {
			const extension = filename.split('.').pop().toLowerCase();
			return extension === 'xls' || extension === 'xlsx';
		},
		setFieldsToNull() {
			this.model.issuer = null;
			this.model.issued = null;
			this.model.expires = null;
			this.model.issuecountry = null;
			this.model.issueport = null;
			this.model.lastsurvey = null;
		},
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'tab_documents');
			}
			const model = this.$store.getters.getModelByName(this.documentInfo.parent.modelName);

			if (model.defaultConfiguration.documentsShowRole) {
				this.showRoles = true;
				if (model.defaultConfiguration.documentsRoles) {
					for (var key in model.defaultConfiguration.documentsRoles) {
						this.roleItems.push({ role: model.defaultConfiguration.documentsRoles[key] });
					}
				} else {
					this.$puiRequests.getRequest('/puidocument/getRoles', null, (response) => {
						if (response && response.data) {
							for (var key in response.data) {
								this.roleItems.push({ role: response.data[key] });
							}
						}
					});
				}
			}

			if (model.defaultConfiguration.documentsShowLanguage) {
				this.showLanguages = true;
			}

			if (this.$store.state.puidocument.enableDragDrop && this.$store.state.puidocument.droppedFile) {
				this.onFileDropped(this.$store.state.puidocument.droppedFile);
				this.$store.state.puidocument.droppedFile = null;
			}
		},
		pickFile() {
			this.$refs.document.click();
		},
		onFilePicked(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				this.onFileDropped(files[0]);
			} else {
				this.model.puidocument.filenameorig = null;
				this.model.puidocument.filename = null;
				this.selectedFile = null;
				this.selectedFileType = null;
			}
		},
		onDragEnter(e) {
			if (e.target.className === 'drop align-center') {
				e.target.style.border = '2px dashed #166bf2';
			}
		},
		onDragLeave(e) {
			if (e.target.className === 'drop align-center') {
				e.target.style.border = '2px dashed var(--N-100)';
			}
		},
		onDrop(e) {
			e.stopPropagation();
			e.preventDefault();
			e.target.style.border = '2px dashed var(--N-100)';
			const files = e.dataTransfer.files;
			this.onFileDropped(files[0]);
		},
		onFileDropped(file) {
			if (file.name.lastIndexOf('.') <= 0) {
				return;
			} else if (!this.validFileExtension(file)) {
				this.$puiNotify.error(this.$t('form.puidocument.error.fileextension.notallowed'));
				return;
			}

			const self = this;
			this.model.puidocument.filenameorig = file.name;
			this.model.puidocument.filename = file.name;
			const fr = new window.FileReader();
			fr.addEventListener('load', (event) => {
				if (fr.result === 'data:') {
					// empty file selected
					self.showEmptyFileDialog = true;
					self.model.puidocument.filenameorig = null;
					self.model.puidocument.filename = null;
					self.selectedFile = null;
					self.selectedFileType = null;
				} else if (self.compressImage && (file.type === 'image/png' || file.type === 'image/jpeg')) {
					const afterCompressImageFile = function (newFile) {
						// newFile is the compress image that will be sent to the server...
						self.selectedFile = newFile;
						self.selectedFileType = self.fileTypes[newFile.type];
					};
					this.$puiUtils.compressImageFile(
						file,
						event,
						self.compressImage.maxResolution,
						self.compressImage.quality,
						afterCompressImageFile
					);
				} else {
					// file is the file that will be sent to the server...
					self.selectedFile = file;
					self.selectedFileType = self.fileTypes[file.type];
				}
			});
			fr.readAsDataURL(file);
		},
		validFileExtension(file) {
			if (this.fileExtensions === null) {
				return true;
			}
			const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
			if (this.fileExtensions.includes('*') || this.fileExtensions.includes(fileExtension)) {
				return true;
			}
			return false;
		},
		insert(shouldStayInPage) {
			let modelName = this.documentInfo.parent.modelName;
			if (this.$store.state.puidocumentModels && this.$store.state.puidocumentModels[modelName]) {
				modelName = this.$store.state.puidocumentModels[modelName];
			}
			this.model.model = modelName;
			this.model.pk = this.documentInfo.parent.pk;

			const formData = new window.FormData();
			formData.append('file', this.selectedFile, this.selectedFile.name);

			this.saving = true;
			this.$puiRequests.uploadFileRequest(
				ENDPOINT_DOCUMENT_UPLOAD,
				formData,
				(response) => {
					this.saving = false;
					if (response.status === 200) {
						this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
						this.afterSave && this.afterSave(response.data);
						if (!(shouldStayInPage === true)) {
							if (this.internal.afterInsertGoToUpdate) {
								this.goToUpdate(response.data);
							} else if (this.internal.afterInsertGoToCreate) {
								// Router not allow to go same router with same parameters
								this.refreshRouter();
							} else {
								this.back();
							}
						}
					} else {
						this.onError(response);
					}
					this.resetAfterSaveActions();
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				},
				this.model
			);
		},
		downloadFile() {
			for (let index = 0, length = DocumentExtraFieldsActions.actions.length; index < length; index++) {
				const action = DocumentExtraFieldsActions.actions[index];
				if (action.id === 'downloadFileAction') {
					action.runAction.call(this, action, this.modelDependency, [this.model]);
				}
			}
		},
		openFile() {
			const url = '/documentextrafields/download';
			const params = this.model;
			let selectedRegistry = params;
			this.$puiRequests.downloadFileRequest(
				'get',
				url,
				params,
				(response) => {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(new window.Blob([response.data]));
					link.download = selectedRegistry.puidocument.filenameorig;
					//link.click();
					let type = '';
					switch (this.model.puidocument.filenameorig.split('.')[1]) {
						case 'pdf':
							type = 'application/pdf';
							break;
						case 'png':
							type = 'image/png';
							break;
						case 'jpg':
							type = 'image/jpeg';
							break;
						case 'jpeg':
							type = 'image/jpeg';
							break;
						case 'txt':
							type = 'text/plain';
							break;
					}
					//this.$router.push(window.URL.createObjectURL(new window.Blob([response.data])));
					window.open(window.URL.createObjectURL(new Blob([response.data], { type: type })), '_blank');

					selectedRegistry = null;
				},
				() => {}
			);
		}
	}
};
</script>

<style scoped>
.align-center {
	text-align: center;
}
.helper {
	height: 100%;
	display: inline-block;
	vertical-align: middle;
	width: 0;
}
.label-file {
	color: var(--primarycolor);
	font-weight: bold;
	font-size: 18px;
}
.drop {
	background-color: var(--N-10);
	border-radius: 4px;
	border: 2px dashed var(--N-100);
	height: 100%;
	width: 100%;
	max-height: 200px;
}
#selectFile {
	color: white !important;
	margin-left: 2%;
	margin-bottom: 3%;
}
</style>
